<template>
    <div>
        <a-modal title="核销记录" :width="840" :footer="null" :visible="visible"  @cancel="handleCancel" >
            <a-table rowKey="write_off_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                     :data-source="datas" :loading="loading">
            </a-table>
        </a-modal>
    </div>
</template>

<script>
    export default{
        props:{
            visible:{
                type:Boolean,
                default:false,
            },
            member_once_project_id:{
                type:Number,
                default:0,
            },
            member_once_card_id:{
                type:Number,
                default:0,
            },
            member_id:{
                type:Number,
                default:0,
            },
        },
        data(){
          return {
              loading:true,
              pagination: {
                  current: 1,
                  pageSize: 10, //每页中显示10条数据
                  total: 0,
              },
              columns:[
                  {title: '编号',dataIndex: 'write_off_id',align: 'center'},
                  {title: '服务项目',dataIndex: 'abbreviation',align: 'center',width:200,ellipsis: true},
                  {title: '核销数量',dataIndex: 'num',align: 'center',width: 150,ellipsis: true},
                  {title: '核销人员',dataIndex: 'operate_name',align: 'center'},
                  {title: '核销备注',dataIndex: 'remark',align: 'center'},
                  {title: '核销时间',dataIndex: 'add_time_format',align: 'center',ellipsis: false},

              ],
              datas: [],
          };
        },
        created() {
            this.visible = true;
            this.getWoffLists();

        },
        methods:{
            getWoffLists(){
                this.$http.api('admin/getMemberOnceCardLogs', {
                    member_once_card_id: this.member_once_card_id,
                    member_id: this.member_id,
                    member_once_project_id: this.member_once_project_id,
                    page: this.pagination.current,
                    limit: this.pagination.pageSize,
                    type: 1,
                }).then(res => {
                    this.datas = res.list;
                    this.pagination.total = res.total;
                    this.loading = false;
                }).catch(res => {
                })
            },
            handleCancel(){
                this.$emit("cancel");
            },
            handleTableChange(){
                this.pagination.current = pagination.current;
                this.getWoffLists();
            },
        }

    };


</script>
